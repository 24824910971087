import React from 'react';
import { graphql, Link } from 'gatsby';
import Header from '../components/Header';
import heroGraphic from '../assets/HeroGraphic.svg';
import Footer from '../components/Footer';

const Press = ({ data }) => {
	const posts = data.allMarkdownRemark.edges;

	return (
		<>
			<Header />
			<div className="bg-white pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-28 lg:px-8">
				<div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl px-4 sm:px-6 py-5">
					<div className="relative ">

						<h1 className="z-10 relative ">
							Press
						</h1>
						<img
							src={heroGraphic}
							alt="Swirl Line"
							className="-left-28 -top-4 lg:-left-28 lg:-top-12 absolute overflow-hidden z-0"
						/>

						<div className="mt-3 sm:mt-4 flex flex-col">
							{/* <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center"> */}

							<div className=" py-5 lg:pt-10 flex flex-col lg:flex-row justify-between lg:items-center">
								<div className="flex flex-col justify-start lg:flex-row lg:justify-between">
									<div className=" flex flex-col mb-5 mr-20">
										<h3> Address:</h3>
										<p>
											P.O. Box 81
											{' '}
											<br />
											Richmond, VA 23218-0081
										</p>
									</div>
									<div className="flex flex-col">
										<h3 className="font-bold">
											Contact:
										</h3>

										<p>
											<a href="tel:8334628339">
												833.462.8339
											</a>
											{' '}
											<br />
											<a href="mailto:buddy@buddy.insure">
												buddy@buddy.insure
											</a>
										</p>
									</div>
								</div>
								<Link
									to="/contact"
									className="buddy-btn-mobelux w-full lg:w-1/6 h-5 mt-5 lg:mt-0"
								>
									Contact Us
								</Link>
							</div>
						</div>
					</div>
					<div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
						{posts.map((post) => (
							<div key={post.title}>
								<p className="text-sm text-gray-500">
									<time dateTime={post.datetime}>{post.node.frontmatter.date}</time>
								</p>
								<Link to={post.node.fields.slug} className="mt-2 block">
									<p className="text-xl font-semibold text-gray-900">{post.node.frontmatter.title}</p>
									<p className="mt-3 text-base text-gray-500">{post.node.frontmatter.description}</p>
								</Link>
								<div className="mt-3">
									<Link to={post.node.fields.slug} className="text-base font-semibold text-indigo-600 hover:text-indigo-500">
										Read full story
									</Link>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Press;

export const query = graphql`
	query {
		allMarkdownRemark(
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { fields: { slug: { regex: "/press/" } } }
		) {
			totalCount
			edges {
				node {
					id
					frontmatter {
						author
						title
						categories
						date(formatString: "MMM DD, YYYY")
						description
					}
					fields {
						slug
					}
					excerpt
				}
			}
		}
	}
`;
